import { useState } from 'react'
import axios, { AxiosError } from 'axios'

import { handleError } from '../utils/utils.ts'

import { UpdateStatus } from '../types/enums/updateStatus.enum.ts'

import { SpreadsheetProfile } from '../types/accountTypes'

export enum ProfileGroups {
    TocaBoca = 'Toca Boca',
}

export function useProfileGroupsFromGoogleSheet() {
    const [profiles, setProfiles] = useState<SpreadsheetProfile[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    const loadGoogleSheetData = async (group: ProfileGroups) => {
        setError(undefined)
        setIsLoading(true)

        try {
            const { data } = await axios.get(`/api/v2/profiles-group/${group}`)

            setProfiles(data)
        } catch (error) {
            const errorMessage = handleError(
                "Could not load the group's profiles from the spreadsheet",
                error,
                false
            )

            setProfiles([])
            setError(errorMessage)
        }

        setIsLoading(false)
    }

    return {
        profiles,
        fetch: loadGoogleSheetData,
        isLoading: isLoading,
        error,
    }
}

export type ProfileUpdateWithStatus = SpreadsheetProfile & {
    productIds: string[]
    status: UpdateStatus
    error?: string
}

export function useAddProductsToMultipleProfiles() {
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [profilesUpdateState, setProfilesUpdateState] = useState<
        ProfileUpdateWithStatus[]
    >([])

    const addProducts = async (
        profile: ProfileUpdateWithStatus
    ): Promise<ProfileUpdateWithStatus> => {
        try {
            await axios.post(
                `/api/v2/profile/${profile.profileId}/addproducts`,
                {
                    productIds: profile.productIds,
                }
            )

            return {
                ...profile,
                status: UpdateStatus.Updated,
                error: '',
            }
        } catch (error) {
            const errorMessage = handleError(
                `Could not update the profile`,
                error,
                false
            )

            if (error instanceof AxiosError && error.status === 400) {
                return {
                    ...profile,
                    status: UpdateStatus.Invalid,
                    error: 'Profile ID is invalid',
                }
            }

            return {
                ...profile,
                status: UpdateStatus.Error,
                error: errorMessage,
            }
        }
    }

    const AllowedStatuses = [UpdateStatus.Error, UpdateStatus.NotStarted]

    const addToMultipleProfiles = async (
        profiles: ProfileUpdateWithStatus[]
    ) => {
        setIsUpdating(true)

        const profilesToUpdate = profiles
            .filter((profile) => AllowedStatuses.includes(profile.status))
            .map((profile) => {
                return {
                    ...profile,
                    status: UpdateStatus.Scheduled,
                }
            })

        setProfilesUpdateState(Array.from(profilesToUpdate))

        for (const [index, profile] of profilesToUpdate.entries()) {
            profilesToUpdate.splice(index, 1, {
                ...profile,
                status: UpdateStatus.Updating,
            })

            setProfilesUpdateState(Array.from(profilesToUpdate))

            const productUpdateResult = await addProducts(profile)

            profilesToUpdate.splice(index, 1, productUpdateResult)

            setProfilesUpdateState(Array.from(profilesToUpdate))
        }

        setIsUpdating(false)
    }

    return {
        addToMultipleProfiles,
        profilesUpdateState,
        isUpdating,
    }
}
