import { ChangeEvent, useEffect, useState } from 'react'
import { isNumber } from 'lodash'

import ModalV2 from '../../shared/ModalV2'
import { RecordUpdateRow } from '../../shared/RecordUpdateRow/RecordUpdateRow.tsx'
import Selector from '../../shared/Selector.tsx'
import { Loading, ManagePlaysets, Section } from '../../shared'

import {
    ProfileGroups,
    ProfileUpdateWithStatus,
    useAddProductsToMultipleProfiles,
    useProfileGroupsFromGoogleSheet,
} from '../../hooks/accounts.ts'
import { usePacks } from '../../hooks/packs.ts'

import { handleError } from '../../utils/utils.ts'

import { UpdateStatus } from '../../types/enums/updateStatus.enum.ts'

import { Pack } from '../../types/packTypes'

import styles from './GroupsManagement.module.scss'

export const GroupsManagementPage = () => {
    const GroupToTitle = {
        [ProfileGroups.TocaBoca]: 'Toca Boca Employees',
    }

    const [packIdsToAdd, setPackIdsToAdd] = useState<string[]>([])
    const [isModalShown, setIsModalShown] = useState<boolean>(false)
    const [selectedGroup, setSelectedGroup] = useState<ProfileGroups | string>(
        ''
    )
    const [profilesWithStatus, setProfilesWithStatus] = useState<
        ProfileUpdateWithStatus[]
    >([])
    const [selectedProfiles, setSelectedProfiles] = useState<
        Map<string, number>
    >(new Map())
    const [isShowAllProfiles, setIsShowAllProfiles] = useState(true)
    const {
        packs: allPacks = [],
        fetch: fetchPacks,
        isLoading: isPacksLoading,
        error: packsError,
    } = usePacks()
    const {
        profiles: allProfiles,
        fetch: fetchGroup,
        isLoading: isGroupLoading,
        error: groupsError,
    } = useProfileGroupsFromGoogleSheet()
    const {
        addToMultipleProfiles,
        profilesUpdateState,
        isUpdating: isUpdatingProfiles,
    } = useAddProductsToMultipleProfiles()

    useEffect(() => {
        fetchPacks()
    }, [])

    useEffect(() => {
        if (!selectedGroup) {
            return
        }

        fetchGroup(selectedGroup as ProfileGroups)
    }, [selectedGroup])

    useEffect(() => {
        if (!profilesWithStatus) {
            return
        }

        const nextProfilesWithStatus = [...profilesWithStatus]

        for (const profileUpdate of profilesUpdateState) {
            const index = selectedProfiles.get(profileUpdate.profileId)

            if (!isNumber(index)) {
                continue
            }

            nextProfilesWithStatus.splice(index, 1, profileUpdate)

            if (
                profileUpdate.status === UpdateStatus.Updated ||
                profileUpdate.status === UpdateStatus.Invalid
            ) {
                const nextSelectedProfiles = new Map(selectedProfiles)

                nextSelectedProfiles.delete(profileUpdate.profileId)

                setSelectedProfiles(nextSelectedProfiles)
            }
        }

        setProfilesWithStatus(nextProfilesWithStatus)
    }, [profilesUpdateState])

    const onGroupChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const group = e.target.value as ProfileGroups

        setSelectedGroup(group)
    }

    const togglePack = (pack: Pack) => {
        const packs = new Set(packIdsToAdd)
        const packId = pack.packId

        if (packs.has(packId)) {
            packs.delete(packId)
        } else {
            packs.add(packId)
        }

        setPackIdsToAdd(Array.from(packs))
    }

    const onAddSelectedClick = () => {
        setSelectedProfiles(
            new Map(allProfiles.map((p, index) => [p.profileId, index]))
        )
        setProfilesWithStatus(
            allProfiles.map((profile) => ({
                ...profile,
                productIds: packIdsToAdd.reduce((acc: string[], packId) => {
                    const pack = allPacks.find((pack) => pack.packId === packId)
                    const productId = pack?.priceLevels[0].productId

                    if (!productId) {
                        const errorMessage = `Couldn't find product id for pack ${packId}`

                        handleError(errorMessage, new Error(errorMessage))
                    } else {
                        acc.push(productId)
                    }

                    return acc
                }, []),
                status: UpdateStatus.NotStarted,
            }))
        )
        setIsModalShown(true)
    }

    const onProfileCheckboxClicked = (profileId: string) => {
        const nextSelectedProfiles = new Map(selectedProfiles)

        if (nextSelectedProfiles.has(profileId)) {
            nextSelectedProfiles.delete(profileId)
        } else {
            nextSelectedProfiles.set(
                profileId,
                allProfiles.findIndex((p) => p.profileId === profileId)
            )
        }

        setSelectedProfiles(nextSelectedProfiles)
    }

    const onModalCloseClicked = () => {
        setIsModalShown(false)
    }

    const onModalUpdateConfirmClicked = () => {
        if (isUpdatingProfiles) {
            return
        }

        addToMultipleProfiles(
            Array.from(selectedProfiles).map(([, profileIndex]) => {
                return profilesWithStatus[profileIndex]
            })
        )
    }

    if (isPacksLoading) {
        return <Loading text="Loading..." />
    }

    if (packsError || groupsError) {
        return (
            <Section title="Groups management">
                {packsError ? <div>{packsError}</div> : null}
                {groupsError ? <div>{groupsError}</div> : null}
            </Section>
        )
    }

    return (
        <Section title="Groups management">
            <div className={styles.controlPanel}>
                <Selector
                    id="profiles-group"
                    name="profiles-group"
                    onChange={onGroupChange}
                    disabled={isGroupLoading}
                >
                    <option value="">-- Select the group to update --</option>
                    <option value={ProfileGroups.TocaBoca}>
                        {GroupToTitle[ProfileGroups.TocaBoca]}
                    </option>
                </Selector>
                <button
                    type="button"
                    onClick={onAddSelectedClick}
                    disabled={
                        isGroupLoading || !selectedGroup || !packIdsToAdd.length
                    }
                >
                    Update selected
                </button>
            </div>

            <ManagePlaysets
                items={packIdsToAdd}
                playsets={allPacks}
                togglePlayset={togglePack}
            />

            {isModalShown && (
                <ModalV2>
                    <div className={styles.modalHeader}>
                        <h1>
                            {GroupToTitle[selectedGroup as ProfileGroups]}{' '}
                            Management
                        </h1>
                        <fieldset>
                            <legend>Status</legend>
                            <input
                                checked={isShowAllProfiles}
                                id={`showAll`}
                                name={'showAll'}
                                type="radio"
                                onChange={() => setIsShowAllProfiles(true)}
                            />
                            <label htmlFor="showAll">Show all</label>
                            <input
                                checked={!isShowAllProfiles}
                                id={`hideUpdated`}
                                name={'hideUpdated'}
                                type="radio"
                                onChange={() => setIsShowAllProfiles(false)}
                            />
                            <label htmlFor="hideUpdated">Hide updated</label>
                        </fieldset>
                    </div>
                    <div className={styles.modalContent}>
                        {profilesWithStatus.map((p) => {
                            if (
                                !isShowAllProfiles &&
                                p.status === UpdateStatus.Updated
                            ) {
                                return null
                            }

                            return (
                                <RecordUpdateRow
                                    key={p.profileId}
                                    id={p.profileId}
                                    text={`${p.profileId} (${p.name})`}
                                    status={p.status}
                                    error={p.error}
                                    isSelected={selectedProfiles.has(
                                        p.profileId
                                    )}
                                    isUpdating={isUpdatingProfiles}
                                    onCheckboxClick={onProfileCheckboxClicked}
                                />
                            )
                        })}
                    </div>
                    <div className={styles.buttons}>
                        <button
                            className="danger"
                            type="button"
                            disabled={isUpdatingProfiles}
                            onClick={onModalCloseClicked}
                        >
                            Close
                        </button>
                        <button
                            className="primary"
                            type="button"
                            disabled={
                                isUpdatingProfiles ||
                                selectedProfiles.size === 0
                            }
                            onClick={onModalUpdateConfirmClicked}
                        >
                            Update
                        </button>
                    </div>
                </ModalV2>
            )}
        </Section>
    )
}
