import { MouseEvent, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    OnDragEndResponder,
} from '@hello-pangea/dnd'
import classNames from 'classnames'

import { useComputedSizes } from '../../hooks/utils.ts'

import { RouteTo } from '../../routes.ts'
import { shopTabTypeLabels } from '../../settings'

import { Tab } from '../../types/tabTypes'

type ShopTabTableProps = {
    tabs: Tab[]
    onTabClick: (id: Tab['id']) => void
    onDragEnd: OnDragEndResponder
    onCopyTabClick: (e: MouseEvent<HTMLButtonElement>, tab: Tab) => void
    onDeleteTabClick: (e: MouseEvent<HTMLButtonElement>, id: Tab['id']) => void
    onToggleActiveClick: (e: MouseEvent<HTMLButtonElement>, tab: Tab) => void
}

const ShopTabsTable = function (props: ShopTabTableProps) {
    const { tabs } = props

    const refs = {
        name: useRef<HTMLTableCellElement>(null),
        type: useRef<HTMLTableCellElement>(null),
        buttons: useRef<HTMLTableCellElement>(null),
        draghandle: useRef<HTMLTableCellElement>(null),
        row: useRef<HTMLTableRowElement | null>(null),
    }
    const { computedSizes, getComputedSizes } = useComputedSizes(refs)

    const navigate = useNavigate()

    const getTdStyle = (isDragging: boolean, columnName: keyof typeof refs) => {
        return isDragging
            ? {
                  ...computedSizes.current[columnName],
                  backgroundColor: '#EFF8FB',
              }
            : {}
    }

    const getDraggableRow = (
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        tab: Tab
    ) => (
        <tr
            key={'tr_tab_draggablerow_id_' + tab.id}
            onClick={() => props.onTabClick(tab.id)}
            className="draggableTab"
            ref={(ref) => {
                refs.row.current = ref
                provided.innerRef(ref)
            }}
            style={{ ...computedSizes.current.row }}
            {...provided.draggableProps}
        >
            <td
                ref={refs.name}
                className={'alignLeft'}
                style={getTdStyle(snapshot.isDragging, 'name')}
            >
                {tab.name}
            </td>
            <td
                ref={refs.type}
                className={'alignLeft'}
                style={getTdStyle(snapshot.isDragging, 'type')}
            >
                {shopTabTypeLabels[tab.type]}
            </td>
            <td
                ref={refs.buttons}
                className={'alignRight'}
                style={getTdStyle(snapshot.isDragging, 'buttons')}
            >
                <button
                    style={{ margin: '0 3px' }}
                    className={classNames(
                        'small',
                        !tab.active ? 'positive' : 'warning'
                    )}
                    onClick={(e) => props.onToggleActiveClick(e, tab)}
                >
                    {!tab.active ? 'Activate' : 'Deactivate'}
                </button>
                <button
                    style={{ margin: '0 3px' }}
                    className="primary small"
                    onClick={() => navigate(RouteTo.TabEdit(String(tab.id)))}
                >
                    Edit tab
                </button>
                <button
                    style={{ margin: '0 3px' }}
                    className="small"
                    onClick={(e) => props.onCopyTabClick(e, tab)}
                >
                    Copy to...
                </button>
                <button
                    style={{ margin: '0 3px' }}
                    className="danger small"
                    onClick={(e) => props.onDeleteTabClick(e, tab.id)}
                >
                    Delete
                </button>
            </td>
            <td
                ref={refs.draghandle}
                style={getTdStyle(snapshot.isDragging, 'draghandle')}
                className="alignRight dragHandle"
                key={'ts_handle_' + tab.id}
                {...provided.dragHandleProps}
            >
                ≡
            </td>
        </tr>
    )

    const renderTable = (tabs: Tab[]) => {
        return (
            <DragDropContext
                key={'tabs_dragdropcontext'}
                onBeforeDragStart={getComputedSizes}
                onDragEnd={props.onDragEnd}
            >
                <Droppable droppableId="droppableTab">
                    {(provided) => (
                        <tbody
                            key={'tabs_tbody'}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {tabs.map((tab, index) => (
                                <Draggable
                                    key={tab.id.toString()}
                                    draggableId={tab.id.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) =>
                                        getDraggableRow(provided, snapshot, tab)
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'left' }}>
                        <label htmlFor="name" style={{ marginRight: 0 }}>
                            String ID
                        </label>
                    </th>
                    <th style={{ textAlign: 'left' }}>
                        <label htmlFor="type" style={{ marginRight: 0 }}>
                            Tab type
                        </label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="buttons"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="draghandle"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                </tr>
            </thead>
            {renderTable(tabs)}
        </table>
    )
}

export default ShopTabsTable
