import classNames from 'classnames'

import { UpdateStatus } from '../../types/enums/updateStatus.enum.ts'

import styles from './RecordUpdateRow.module.scss'

const UpdateStatusToClass: Record<UpdateStatus, string> = {
    [UpdateStatus.NotStarted]: styles.notStarted,
    [UpdateStatus.Updating]: styles.updating,
    [UpdateStatus.Scheduled]: styles.scheduled,
    [UpdateStatus.Updated]: styles.updated,
    [UpdateStatus.Error]: styles.error,
    [UpdateStatus.Invalid]: styles.invalid,
}

const UpdateStatusToText: Record<UpdateStatus, string> = {
    [UpdateStatus.NotStarted]: 'Not started',
    [UpdateStatus.Updating]: 'Updating',
    [UpdateStatus.Scheduled]: 'Scheduled',
    [UpdateStatus.Updated]: 'Updated',
    [UpdateStatus.Error]: 'Error',
    [UpdateStatus.Invalid]: 'Invalid',
}

type RecordUpdateRowProps = {
    id: string
    text: string
    status: UpdateStatus
    statusText?: string
    error?: string
    isSelected: boolean
    isUpdating: boolean
    onCheckboxClick: (id: string) => void
}

export const RecordUpdateRow = (props: RecordUpdateRowProps) => {
    const { id, text, status, statusText, isSelected, error, isUpdating } =
        props

    const onCheckboxClick = () => {
        props.onCheckboxClick(id)
    }

    return (
        <div className={styles.productRow}>
            <input
                id={id}
                type="checkbox"
                className={styles.checkbox}
                checked={isSelected}
                onChange={onCheckboxClick}
                disabled={
                    UpdateStatus.Updated === status ||
                    UpdateStatus.Invalid === status ||
                    isUpdating
                }
            />

            <div
                className={classNames(
                    styles.status,
                    UpdateStatusToClass[status]
                )}
                title={error}
            >
                {statusText || UpdateStatusToText[status]}
            </div>

            <div>
                <label htmlFor={id}>{text}</label>
                {(status === UpdateStatus.Error ||
                    status === UpdateStatus.Invalid) &&
                error ? (
                    <p className={styles.errorMessage}>{error.trim()}</p>
                ) : null}
            </div>
        </div>
    )
}
