import { useState } from 'react'
import axios from 'axios'

import { handleError } from '../utils/utils.ts'

import { PriceAutomationStore } from '../types/enums/store.enum.ts'
import { UpdateStatus } from '../types/enums/updateStatus.enum.ts'

import {
    Price,
    ProductPrices,
    ProductWithStatus,
} from '../types/priceAutomation'

const isPricesValid = (prices: Price[]) => {
    return prices.every((p1) => p1.price !== null)
}

export function useSkuPricesFromGoogleSheet(store: PriceAutomationStore) {
    const [products, setProducts] = useState<ProductWithStatus[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()

    const loadGoogleSheetData = async () => {
        setError(undefined)
        setIsLoading(true)

        try {
            const { data } = await axios.get(
                `/api/v2/inapppurchases/products/${store}`
            )

            const newProducts = data.map((product: ProductPrices) => ({
                ...product,
                status: isPricesValid(product.prices)
                    ? UpdateStatus.NotStarted
                    : UpdateStatus.Invalid,
            }))

            setProducts(newProducts)
        } catch (error) {
            const errorMessage = handleError(
                'Could not load sku prices from the spreadsheet',
                error,
                false
            )

            setProducts([])
            setError(errorMessage)
        }

        setIsLoading(false)
    }

    return {
        productsToUpdate: products,
        fetchProducts: loadGoogleSheetData,
        isLoading: isLoading,
        error,
    }
}

export function usePriceUpdater(store: PriceAutomationStore) {
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [productsUpdateState, setProductsUpdateState] = useState<
        ProductWithStatus[]
    >([])

    const updateProductApple = (productId: string, prices: Price[]) => {
        return axios.post(`/api/v2/inapppurchases/${productId}/prices`, {
            prices,
        })
    }

    const updateProductGoogle = (productId: string, prices: Price[]) => {
        return axios.post(
            `/api/v2/googleplay/inapppurchases/${productId}/prices`,
            {
                prices,
            }
        )
    }

    const updateProduct = async ({
        productId,
        prices,
    }: ProductWithStatus): Promise<ProductWithStatus> => {
        try {
            if (store === PriceAutomationStore.APPLE) {
                await updateProductApple(productId, prices)
            } else if (store === PriceAutomationStore.GOOGLE) {
                await updateProductGoogle(productId, prices)
            } else {
                throw new Error(`Unsupported store ${store}`)
            }

            return {
                prices,
                productId,
                status: UpdateStatus.Updated,
                error: '',
            }
        } catch (error) {
            const errorMessage = handleError(
                'Could not update the prices for this SKU',
                error,
                false
            )

            return {
                prices,
                productId,
                status: UpdateStatus.Error,
                error: errorMessage,
            }
        }
    }

    const AllowedStatuses = [UpdateStatus.Error, UpdateStatus.NotStarted]

    const updateProducts = async (products: ProductWithStatus[]) => {
        setIsUpdating(true)

        const productsToUpdate = products
            ?.filter((product) => AllowedStatuses.includes(product.status))
            .map((product) => {
                return {
                    ...product,
                    status: UpdateStatus.Scheduled,
                }
            })

        setProductsUpdateState(Array.from(productsToUpdate))

        for (const [index, product] of productsToUpdate.entries()) {
            productsToUpdate.splice(index, 1, {
                ...product,
                status: UpdateStatus.Updating,
            })

            setProductsUpdateState(Array.from(productsToUpdate))

            const productUpdateResult = await updateProduct(product)

            productsToUpdate.splice(index, 1, productUpdateResult)

            setProductsUpdateState(Array.from(productsToUpdate))
        }

        setIsUpdating(false)
    }

    return {
        updateProductsPrices: updateProducts,
        productsUpdateState: productsUpdateState,
        isUpdatingPrices: isUpdating,
    }
}
