import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
} from '@hello-pangea/dnd'
import classNames from 'classnames'

import { useComputedSizes } from '../hooks/utils'

import { RouteTo } from '../routes'

import { Carousel } from '../types/carouselTypes'

type CarouselsTableProps = {
    carousels: Carousel[]
    onCarouselDragEnd: (result: DropResult) => void
    onActivateCarouselClick: (carousel: Carousel) => Promise<void>
    onCopyCarouselClick: (carouselName: Carousel['carouselName']) => void
    onCarouselDeleteClick: (i: number) => Promise<void>
}

const CarouselsTable = (props: CarouselsTableProps) => {
    const { carousels } = props
    const navigate = useNavigate()

    const refs = {
        name: useRef<HTMLTableCellElement | null>(null),
        buttons: useRef<HTMLTableCellElement | null>(null),
        dragHandler: useRef<HTMLTableCellElement | null>(null),
        row: useRef<HTMLTableRowElement | null>(null),
    }
    const { computedSizes, getComputedSizes } = useComputedSizes(refs)

    const getTdStyle = (isDragging: boolean, columnName: keyof typeof refs) => {
        return isDragging
            ? {
                  ...computedSizes.current[columnName],
                  backgroundColor: '#EFF8FB',
              }
            : {}
    }

    const getDraggableRow = (
        provided: DraggableProvided,
        snapshot: DraggableStateSnapshot,
        c: Carousel,
        i: number
    ) => {
        return (
            <tr
                className="draggableCarousel"
                onClick={() =>
                    navigate(RouteTo.CarouselEdit(String(c.carouselId)))
                }
                ref={(element) => {
                    refs.row.current = element
                    provided.innerRef(element)
                }}
                style={{ ...computedSizes.current.row }}
                {...provided.draggableProps}
            >
                <td
                    ref={refs.name}
                    className={'alignLeft'}
                    style={getTdStyle(snapshot.isDragging, 'name')}
                >
                    {c.carouselName}
                </td>

                <td
                    ref={refs.buttons}
                    className={'alignRight'}
                    style={getTdStyle(snapshot.isDragging, 'buttons')}
                >
                    <button
                        style={{ margin: '0 3px' }}
                        className={classNames(
                            'small',
                            !c.active ? 'positive' : 'warning'
                        )}
                        onClick={(e) => {
                            e.stopPropagation()
                            props.onActivateCarouselClick(c)
                        }}
                    >
                        {!c.active ? 'Activate' : 'Deactivate'}
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small primary"
                        onClick={() =>
                            navigate(RouteTo.CarouselEdit(String(c.carouselId)))
                        }
                    >
                        Edit carousel
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small"
                        onClick={(e) => {
                            e.stopPropagation()
                            props.onCopyCarouselClick(c.carouselName)
                        }}
                    >
                        Copy to...
                    </button>
                    <button
                        style={{ margin: '0 3px' }}
                        className="small danger"
                        onClick={(e) => {
                            e.stopPropagation()
                            props.onCarouselDeleteClick(i)
                        }}
                    >
                        Delete
                    </button>
                </td>

                <td
                    ref={refs.dragHandler}
                    style={getTdStyle(snapshot.isDragging, 'dragHandler')}
                    className="alignRight dragHandle"
                    {...provided.dragHandleProps}
                >
                    ≡
                </td>
            </tr>
        )
    }

    const renderTable = (carousels: Carousel[]) => {
        return (
            <DragDropContext
                key="dnd_carousels"
                onDragEnd={props.onCarouselDragEnd}
                onBeforeDragStart={getComputedSizes}
            >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <tbody ref={provided.innerRef}>
                            {carousels.map((c, i) => (
                                <Draggable
                                    key={c.carouselId.toString()}
                                    draggableId={c.carouselId.toString()}
                                    index={i}
                                >
                                    {(dragProvided, dragSnapshot) =>
                                        getDraggableRow(
                                            dragProvided,
                                            dragSnapshot,
                                            c,
                                            i
                                        )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'left' }}>
                        <label htmlFor="name" style={{ marginRight: 0 }}>
                            Name
                        </label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="buttons"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        <label
                            htmlFor="draghandle"
                            style={{ marginRight: 0 }}
                        ></label>
                    </th>
                </tr>
            </thead>

            {renderTable(carousels)}
        </table>
    )
}

export default CarouselsTable
