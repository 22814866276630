import { ChangeEvent } from 'react'

import { LabelInput, NewRow, SpeechBubble } from '../shared'

import { languages } from '../utils/utils'
import { getTranslationLoadingText } from './utils'

import { LanguageType } from '../types/enums/languagetype.enum'

import { CreateCarousel } from '../types/carouselTypes'

type OverviewProps = {
    language: LanguageType
    carousel: CreateCarousel
    translationCategoryId?: string
    translations?: Record<LanguageType, string>
    isTranslationsPending: boolean
    translationsError: Error | null
    onInputChange: (
        e: ChangeEvent<HTMLInputElement>,
        language?: LanguageType
    ) => void
    onClickLoadTranslations: () => void
}

function Overview(props: OverviewProps) {
    const {
        language,
        carousel,
        translationCategoryId,
        translations,
        isTranslationsPending,
        translationsError,
        onInputChange,
        onClickLoadTranslations,
    } = props

    const translationsLoadingText = getTranslationLoadingText(
        translationCategoryId,
        translations,
        isTranslationsPending,
        translationsError
    )
    return (
        <>
            <LabelInput
                object={carousel}
                size="wide"
                name="carouselName"
                label="Category ID"
                onChange={(e) => onInputChange(e)}
            />
            <LabelInput
                object={carousel}
                size="wide"
                name="displayName"
                tooltip="Localized name"
                language={language}
                label="display name in "
                labelHighlight={languages[language]}
                onChange={(e) => onInputChange(e, language)}
            />
            <NewRow />
            {translationsLoadingText && (
                <SpeechBubble
                    style={{
                        justifyContent: 'left',
                        marginTop: 20,
                        marginBottom: 10,
                    }}
                >
                    {translationsLoadingText}
                </SpeechBubble>
            )}
            <button
                disabled={translationCategoryId !== undefined}
                onClick={() => onClickLoadTranslations()}
            >
                Load from Google Sheet
            </button>
        </>
    )
}

export default Overview
