import { createContext } from 'react'

import { EnvironmentNameType } from '../types/enums/envnametype.enum'

interface OpenSectionsContextType {
    openSections: string[]
    toggleSection: (sectionName: string) => void
}

export const OpenSectionsContext = createContext<
    OpenSectionsContextType | undefined
>(undefined)

interface CurrentEnvironmentContextType {
    currentEnvName: EnvironmentNameType
}

export const CurrentEnvironmentContext = createContext<
    CurrentEnvironmentContextType | undefined
>(undefined)
