import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import Overview from './Overview'
import {
    DraggablePacks,
    Loading,
    ManagePlaysets,
    Modal,
    SavePanel,
    Section,
} from '../shared'

import {
    useCarousels,
    useCarouselTranslations,
    useCreateCarousel,
    useDeleteCarousel,
    useUpdateCarousel,
} from '../hooks/carousels'
import { useOpenSections } from '../hooks/contexts'
import { usePacks } from '../hooks/packs'

import { handleError, moveListItem } from '../utils/utils'
import { getSaveStatus, mapCarouselItemsToPacks } from './utils'

import { RouteTo } from '../routes'

import { LanguageType } from '../types/enums/languagetype.enum'
import { MediaType } from '../types/enums/mediatype.enum'
import { PackType } from '../types/enums/packtype.enum'

import {
    CarouselItem,
    CreateCarousel,
    CreateCarouselItem,
} from '../types/carouselTypes'
import { Pack } from '../types/packTypes'

import validate from './validate'

const emptyCarousel: CreateCarousel = {
    carouselName: '',
    active: false,
    displayName: {},
    priority: 0,
    items: [],
}

type CarouselProps = {
    language: LanguageType
}

const Carousel = (props: CarouselProps) => {
    const { language } = props
    const { openSections, toggleSection } = useOpenSections()
    const navigate = useNavigate()
    const params = useParams()
    const id = Number(params.id)

    const { carousels, isPending: isCarouselsPending } = useCarousels()
    const carousel = carousels?.find((carousel) => carousel.carouselId === id)
    const [carouselDraft, setCarouselDraft] =
        useState<CreateCarousel>(emptyCarousel)

    useEffect(() => {
        if (carousel) {
            setCarouselDraft(carousel)
        }
    }, [carousel])

    const {
        createCarousel,
        createdCarousel,
        isPending: isCreatePending,
        isSuccess: isCreateSuccess,
        error: createError,
    } = useCreateCarousel()
    const {
        updateCarousel,
        isPending: isUpdatePending,
        isSuccess: isUpdateSuccess,
        error: updateError,
    } = useUpdateCarousel()

    useEffect(() => {
        if (!id && isCreateSuccess && createdCarousel) {
            navigate(RouteTo.CarouselEdit(String(createdCarousel.carouselId)))
        }
    }, [id, isCreateSuccess, createdCarousel])

    useEffect(() => {
        if (createError) {
            handleError('Error creating the carousel', createError)
        }
    }, [createError])

    useEffect(() => {
        if (updateError) {
            handleError('Error updating the carousel', updateError)
        }
    }, [updateError])

    const {
        deleteCarousel,
        isSuccess: isDeleteSuccess,
        error: deleteError,
    } = useDeleteCarousel()

    useEffect(() => {
        if (isDeleteSuccess) {
            navigate(RouteTo.Carousels())
        }
    }, [isDeleteSuccess])

    useEffect(() => {
        if (deleteError) {
            handleError('Error deleting carousel', deleteError)
        }
    }, [deleteError])

    useEffect(() => {
        if (carousels && params.id && !carousel && !isDeleteSuccess) {
            handleError(`Carousel with ID ${params.id} was not found`, null)
            navigate(RouteTo.Carousels())
        }
    }, [carousels, params.id, carousel, isDeleteSuccess])

    const [translationCategoryId, setTranslationCategoryId] = useState<string>()
    const {
        carouselTranslations,
        isPending: isTranslationsPending,
        error: translationsError,
    } = useCarouselTranslations(translationCategoryId)

    useEffect(() => {
        if (!_.isEmpty(carouselTranslations)) {
            setCarouselDraft((carouselDraft) => ({
                ...carouselDraft,
                displayName: carouselTranslations,
            }))
        }
    }, [carouselTranslations])

    useEffect(() => {
        if (carouselTranslations || translationsError) {
            setTimeout(() => setTranslationCategoryId(undefined), 5000)
        }
    }, [carouselTranslations, translationsError])

    const [isSavedStatusShown, setIsSavedStatusShown] = useState(false)
    const saveStatus = getSaveStatus({
        original: carousel,
        draft: carouselDraft,
        isSaving: isCreatePending || isUpdatePending,
        isSaved: (isCreateSuccess || isUpdateSuccess) && isSavedStatusShown,
    })

    useEffect(() => {
        if (isCreateSuccess) {
            setIsSavedStatusShown(true)
        }
    }, [isCreateSuccess])

    useEffect(() => {
        if (isUpdateSuccess) {
            setIsSavedStatusShown(true)
        }
    }, [isUpdateSuccess])

    useEffect(() => {
        if (isSavedStatusShown) {
            setTimeout(() => setIsSavedStatusShown(false), 5000)
        }
    }, [isSavedStatusShown])

    const { packs, fetch: fetchPacks, isLoading: isPacksPending } = usePacks()
    useEffect(() => {
        fetchPacks()
    }, [])

    const validationResults = validate(carouselDraft)

    const onInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        language?: LanguageType
    ) => {
        const {
            currentTarget: { name, value },
        } = e
        setCarouselDraft((carouselDraft) => {
            const newCarouselDraft = _.cloneDeep(carouselDraft)
            const propertyPath = [name, language].filter(Boolean).join('.')
            _.set(newCarouselDraft, propertyPath, value)
            return newCarouselDraft
        })
    }

    const togglePackCheckbox = (
        item: CarouselItem | CreateCarouselItem | Pack
    ) => {
        setCarouselDraft((carouselDraft) => {
            const selectedPacks = carouselDraft.items.slice()
            const pack = selectedPacks.find(
                (pack) => pack.packId === item.packId
            )
            if (!pack) {
                selectedPacks.push({ packId: item.packId })
            } else {
                _.pull(selectedPacks, pack)
            }
            return {
                ...carouselDraft,
                items: selectedPacks,
            }
        })
    }

    const packThumbnailDeleteClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        item: Pack
    ) => {
        e.preventDefault()
        togglePackCheckbox(item)
    }

    const packThumbnailDragEnd = (result: {
        source: { index: number }
        destination: { index: number }
    }) => {
        setCarouselDraft((carouselDraft) => {
            const items = moveListItem(
                carouselDraft.items.slice(),
                result.source.index,
                result.destination.index
            )
            return { ...carouselDraft, items }
        })
    }

    const onDelete = async () => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete carousel',
            text: 'Really delete this carousel?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return
        deleteCarousel(id)
    }

    const save = () => {
        if (!id) {
            return createCarousel(carouselDraft)
        }
        updateCarousel({ carouselId: id, carousel: carouselDraft })
    }

    if (isCarouselsPending || isPacksPending) return <Loading />

    const carouselDraftPacks = packs
        ? mapCarouselItemsToPacks(carouselDraft.items, packs)
        : []
    const selectablePacks = packs?.filter(
        (pack) => ![PackType.STANDALONE, PackType.PACK].includes(pack.type)
    )

    return (
        <div>
            <Section
                id="carouselTitle"
                title={id ? carouselDraft.carouselName : 'New carousel'}
                openSections={openSections}
                toggleSection={toggleSection}
            >
                <Overview
                    language={language}
                    carousel={carouselDraft}
                    translationCategoryId={translationCategoryId}
                    translations={carouselTranslations}
                    isTranslationsPending={isTranslationsPending}
                    translationsError={translationsError}
                    onInputChange={onInputChange}
                    onClickLoadTranslations={() =>
                        setTranslationCategoryId(carouselDraft.carouselName)
                    }
                />
            </Section>
            <Section
                id="carouselContentThumbnails"
                title="Arrange packs appearance"
                openSections={openSections}
                toggleSection={toggleSection}
            >
                {!carouselDraft.items.length && (
                    <label className="placeholder">
                        Add some packs from the section below to see thumbnails
                        here
                    </label>
                )}
                <DraggablePacks
                    type="horizontal"
                    onDelete={(e, item) => packThumbnailDeleteClick(e, item)}
                    items={carouselDraftPacks}
                    thumbType={MediaType.THUMBNAIL}
                    onDragEnd={(e) => packThumbnailDragEnd(e)}
                />
            </Section>
            <Section
                id="carouselContent"
                title="Carousel content"
                openSections={openSections}
                toggleSection={toggleSection}
            >
                {selectablePacks && (
                    <ManagePlaysets
                        items={carouselDraft.items}
                        title="Add bundles, playsets, home designer, and character creator packs"
                        playsets={selectablePacks}
                        togglePlayset={togglePackCheckbox}
                    />
                )}
            </Section>
            <SavePanel
                title={`Working with: ${carouselDraft.carouselName}`}
                validationResults={validationResults}
                item={carouselDraft}
                save={save}
                showDelete={Boolean(id)}
                saveStatus={saveStatus}
                onDelete={onDelete}
            />
        </div>
    )
}

export default Carousel
