import { useState } from 'react'
import { components } from '@lifeserver/models/lifev2'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import _ from 'lodash'

import Modal from '../shared/Modal'

import { handleEnvAuth } from '../utils/envaccess'
import {
    fetchDataFromPath,
    postDataForPath,
    putDataForPath,
} from '../utils/envcopy'

import { LanguageType } from '../types/enums/languagetype.enum'

import { Carousel, CreateCarousel } from '../types/carouselTypes'

const QUERY_KEY_CAROUSELS = 'carousels'

export function useCarousels() {
    async function listCarousels() {
        const { data } = await axios.get<
            components['schemas']['CarouselDTO'][]
        >('/api/v2/carousels')
        return data
    }

    const { isPending, error, data } = useQuery({
        queryKey: [QUERY_KEY_CAROUSELS],
        queryFn: listCarousels,
    })

    return { carousels: data, isPending, error }
}

export function useCarouselTranslations(
    carouselName?: Carousel['carouselName']
) {
    async function getCarouselTranslations() {
        const {
            data: { name },
        } = await axios.get<{ name: Record<LanguageType, string> }>(
            `/api/v2/getpackfromsource/${carouselName}`
        )
        return name
    }
    const { data, isPending, error } = useQuery({
        queryKey: ['carouselTranslations', carouselName],
        queryFn: getCarouselTranslations,
        enabled: carouselName !== undefined,
    })

    return {
        carouselTranslations: data,
        isPending,
        error,
    }
}

export function useCreateCarousel() {
    async function createCarousel(carousel: CreateCarousel) {
        const { data } = await axios.post<components['schemas']['CarouselDTO']>(
            '/api/v2/carousel',
            {
                data: carousel,
            }
        )
        return data
    }

    const queryClient = useQueryClient()
    const { mutate, data, isPending, isSuccess, error } = useMutation({
        mutationFn: createCarousel,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_CAROUSELS],
            })
        },
    })

    return {
        createCarousel: mutate,
        createdCarousel: data,
        isPending,
        isSuccess,
        error,
    }
}

type UpdateCarouselRequest = {
    carouselId: Carousel['carouselId']
    carousel: CreateCarousel
}

export function useUpdateCarousel() {
    async function updateCarousel(
        updateCarouselRequest: UpdateCarouselRequest
    ) {
        const { carouselId, carousel } = updateCarouselRequest
        await axios.put(`/api/v2/carousel/${carouselId}`, { data: carousel })
    }

    const queryClient = useQueryClient()
    const { mutate, isPending, isSuccess, error } = useMutation({
        mutationFn: updateCarousel,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_CAROUSELS],
            })
        },
    })

    return {
        updateCarousel: mutate,
        isPending,
        isSuccess,
        error,
    }
}

export function useUpdateCarouselOrder() {
    async function updateCarouselOrder(carouselIds: Carousel['carouselId'][]) {
        await axios.put(`/api/v2/carousels`, { carouselIds })
    }

    const queryClient = useQueryClient()
    const { mutate, error } = useMutation({
        mutationFn: updateCarouselOrder,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_CAROUSELS],
            })
        },
    })

    return {
        updateCarouselOrder: mutate,
        error,
    }
}

type ToggleActiveCarouselRequest = {
    carouselId: Carousel['carouselId']
    isSetActive: boolean
}

export function useToggleActiveCarousel() {
    async function toggleActiveCarousel(
        toggleActiveCarouselRequest: ToggleActiveCarouselRequest
    ) {
        const { carouselId, isSetActive } = toggleActiveCarouselRequest
        await axios.put(`/api/v2/carousels/${carouselId}/toggleactive`, {
            setActive: isSetActive,
        })
    }

    const queryClient = useQueryClient()
    const { mutate, error } = useMutation({
        mutationFn: toggleActiveCarousel,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_CAROUSELS],
            })
        },
    })

    return {
        toggleActiveCarousel: mutate,
        error,
    }
}

export function useCopyCarousel() {
    const [loadingMessage, setLoadingMessage] = useState<string>()

    const copyCarousel = async (carouselName: Carousel['carouselName']) => {
        setLoadingMessage('Copying carousel...')

        const env = await handleEnvAuth()
        if (!env) {
            return setLoadingMessage(undefined)
        }

        // Get and compare carousels
        const currentCarousel = await fetchDataFromPath(
            `/api/v2/carousel/${carouselName}`
        )
        const refCarousel = await fetchDataFromPath(
            `${env.basepath}/v2/carousel/${carouselName}`
        )

        let isConfirmed = false
        if (_.isEmpty(refCarousel)) {
            // No carousel in target environment, show confirm modal
            isConfirmed = await Modal.confirm({
                heading: 'Copy carousel',
                text: `No carousel with name '${carouselName}' found in ${env.name}, do you want to make a copy?`,
                okLabel: 'Yes do it!',
            })
        } else {
            // Carousel found, show compare modal
            isConfirmed = await Modal.compare({
                heading: 'Compare carousels',
                text: 'The following differences were found',
                okLabel: 'Copy',
                object1: refCarousel,
                object2: currentCarousel,
            })
        }
        if (!isConfirmed) {
            return setLoadingMessage(undefined)
        }

        // Prepare carousel content before copy
        setLoadingMessage('Preparing carousel content...')
        // If carousel items exist, we need to check if the packs also exist in target env, if not remove them from this carousel before save
        const items = []
        for (const item of currentCarousel.items) {
            const { pack } = await fetchDataFromPath(
                `${env.basepath}/v2/pack/${item.packId}`
            )
            if (pack) {
                items.push({ packId: pack.packId })
            }
        }
        if (currentCarousel.items.length) {
            // Show modal here of how many packs that were found
            const isConfirmed = await Modal.confirm({
                heading: 'Packs found',
                text: `${items.length} out of ${currentCarousel.items.length} packs were found in ${env.name}, do you still want to continue?`,
                okLabel: 'Continue',
            })
            if (!isConfirmed) {
                return setLoadingMessage(undefined)
            }
        }

        const newCarousel = { ...currentCarousel, items, active: false }
        // Finally, copy carousel to target environment
        setLoadingMessage(`Saving carousel to ${env.name}...`)
        const hasCopiedSuccessfully = _.isEmpty(refCarousel)
            ? await postDataForPath(
                  { data: newCarousel },
                  `${env.basepath}/v2/carousel`
              )
            : await putDataForPath(
                  { data: newCarousel },
                  `${env.basepath}/v2/carousel/${refCarousel.carouselId}`
              )

        setLoadingMessage(undefined)
        if (hasCopiedSuccessfully) {
            await Modal.alert({
                heading: 'Copying done!',
                text: `Woho, the carousel with name '${currentCarousel.carouselName}' was successfully stored in ${env.name}!`,
            })
        } else {
            await Modal.alert({
                heading: 'On no!',
                text: `Something went wrong when trying to copy carousel with name '${currentCarousel.carouselName}' to ${env.name}!`,
            })
        }
    }

    return { copyCarousel, loadingMessage }
}

export function useDeleteCarousel() {
    async function deleteCarousel(carouselId: Carousel['carouselId']) {
        await axios.delete(`/api/v2/carousel/${carouselId}`)
    }

    const queryClient = useQueryClient()
    const { mutate, isSuccess, error } = useMutation({
        mutationFn: deleteCarousel,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QUERY_KEY_CAROUSELS],
            })
        },
    })

    return { deleteCarousel: mutate, isSuccess, error }
}
