import { MouseEvent } from 'react'
import { ValidationResult } from 'joi'

import { SpeechBubble, Validator } from '../shared'

import savingImg from '../assets/saving.png'

export enum SaveStatus {
    NO_CHANGES = 'nochanges',
    SAVING = 'saving',
    SAVED = 'saved',
    HAS_CHANGED = 'hasChanged',
    HIDE = 'hide',
    INVALID = 'invalid',
}

type SaveButtonProps = {
    isDisabled?: boolean
    saveStatus: SaveStatus
    onClick: (e: MouseEvent<HTMLButtonElement>) => void
    validationResults: {
        errors: ValidationResult
        warnings: ValidationResult
    }
}

function SaveButton(props: SaveButtonProps) {
    const { isDisabled, onClick, validationResults } = props
    let saveStatus = props.saveStatus
    let style = {}

    if (validationResults && validationResults.errors.error)
        saveStatus = SaveStatus.INVALID

    if (saveStatus === SaveStatus.SAVED || saveStatus === SaveStatus.SAVING)
        style = { background: 'none', color: 'black' }

    if (saveStatus === SaveStatus.SAVED) {
        return (
            <SpeechBubble style={{ margin: 0, justifyContent: 'left' }}>
                I saved it for you!
            </SpeechBubble>
        )
    }
    return (
        <button
            className="positive"
            style={style}
            disabled={isDisabled || saveStatus !== SaveStatus.HAS_CHANGED}
            onClick={onClick}
        >
            {saveStatus === SaveStatus.INVALID && 'Save'}
            {saveStatus === SaveStatus.NO_CHANGES && 'No changes'}
            {saveStatus === SaveStatus.HAS_CHANGED && 'Save'}
            {saveStatus === SaveStatus.SAVING && 'Saving'}

            {saveStatus === SaveStatus.SAVING && (
                <img
                    alt="loading"
                    className="loading"
                    style={{
                        marginLeft: 10,
                        marginBottom: -6,
                        width: 22,
                        opacity: 0.8,
                    }}
                    src={savingImg}
                ></img>
            )}
        </button>
    )
}

type SavePanelProps<Item> = {
    title: string
    saveStatus: SaveStatus
    item: Item
    isDisabled?: boolean
    save: () => void
    onDelete: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
    validationResults: {
        errors: ValidationResult
        warnings: ValidationResult
    }
    showDelete: boolean
}

function SavePanel<Item>(props: SavePanelProps<Item>) {
    const {
        validationResults,
        item,
        isDisabled,
        save,
        saveStatus,
        onDelete,
        title,
    } = props
    if (saveStatus === SaveStatus.HIDE) return <></>
    return (
        <div className="savePanel">
            <div
                style={{
                    textAlign: 'right',
                    fontSize: '.8em',
                    marginBottom: 5,
                }}
            >
                {title}
            </div>
            <div className="items">
                {validationResults && (
                    <Validator
                        showValid={true}
                        style={{ marginRight: 15 }}
                        validationResults={validationResults}
                        item={item}
                    />
                )}
                <SaveButton
                    isDisabled={isDisabled}
                    onClick={save}
                    validationResults={validationResults}
                    saveStatus={saveStatus}
                />
                {props.showDelete && (
                    <button
                        className="danger"
                        disabled={isDisabled}
                        style={{ marginLeft: 15 }}
                        onClick={onDelete}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    )
}

export default SavePanel
