import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import {
    AppEventAttributes,
    AppEventAttributesBadge,
    AppEventAttributesEventState,
    AppEventAttributesPriority,
    AppEventAttributesPurchaseRequirement,
    AppEventAttributesPurpose,
} from '@tocaboca/app-store-connect'
import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'

import { SaveStatus } from '../shared/SavePanel.tsx'
import {
    Checkbox,
    LabelInput,
    Loading,
    MediaImage,
    Modal,
    NewRow,
    SavePanel,
    Section,
    SpeechBubble,
} from '../shared'

import { handleError, languages } from '../utils/utils'

import { RouteTo } from '../routes.ts'

import { ImageIconType } from '../types/enums/imageicontype.enum'
import { LanguageType } from '../types/enums/languagetype.enum.ts'

import { InAppEvent as InAppEventType } from '../types/inappeventTypes'

import {
    AppEventDateEnum,
    AppEventLocalisationEnum,
    getAppEventDate,
    getAppEventLocalisation,
    getAppEventUrl,
    isHighPriority,
    isInAppPurchaseRequired,
    mapAppEventScreenshotToMedia,
    setAppEventDate,
    setAppEventLocalisation,
} from './appeventfunctions'
import validate from './validate'

type InAppEventProps = {
    language: LanguageType
}

const editableEventStates: AppEventAttributesEventState[] = [
    AppEventAttributesEventState.DRAFT,
    AppEventAttributesEventState.READY_FOR_REVIEW,
    AppEventAttributesEventState.REJECTED,
]

function InAppEvent(props: InAppEventProps) {
    const emptyIae: InAppEventType = {
        appId: '',
        appEvent: {
            id: '',
            attributes: {
                referenceName: '',
                deepLink: 'https://tocaboca.com/app/world/',
                primaryLocale: 'en-US',
                priority: AppEventAttributesPriority.NORMAL,
                purchaseRequirement:
                    AppEventAttributesPurchaseRequirement.NO_COST_ASSOCIATED,
                purpose: AppEventAttributesPurpose.APPROPRIATE_FOR_ALL_USERS,
                territorySchedules: [],
                archivedTerritorySchedules: [],
            },
            type: 'appEvents',
        },
        localisations: [],
        screenshots: [],
    }

    const [iae, setIae] = useState<InAppEventType>(emptyIae)
    const [initialIae, setInitialIae] = useState<InAppEventType>(emptyIae)
    const [isGoogleSheetLoading, setIsGoogleSheetLoading] = useState(false)
    const [googleSheetStatus, setGoogleSheetStatus] = useState('')
    const [isSaved, setIsSaved] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const id = useParams().id
    const isNew = !id
    const [isLoading, setIsLoading] = useState(!isNew)
    const navigate = useNavigate()
    const validationResults = useMemo(() => {
        return validate(iae, isNew)
    }, [iae])
    const { language } = props

    const eventState = iae.appEvent.attributes?.eventState
    const isEditable =
        Boolean(eventState && editableEventStates.includes(eventState)) || isNew

    useEffect(() => {
        if (!isNew) {
            getInAppEvent()
        }
    }, [])

    useEffect(() => {
        if (isSaved) {
            setTimeout(() => {
                setIsSaved(false)
            }, 2000)
        }
    }, [isSaved])

    async function getInAppEvent() {
        try {
            const { data } = await axios.get<InAppEventType>(
                `/api/v2/iae/${id}`
            )
            setIae(data)
            setInitialIae(data)
            setIsLoading(false)
        } catch (err) {
            handleError(`Could not get In-App Event ${id}`, err)
        }
    }

    function getSaveStatus(isSaved: boolean, isSaving: boolean): SaveStatus {
        if (isNew && !isSaving) return SaveStatus.HAS_CHANGED
        if (iae)
            return isSaving
                ? SaveStatus.SAVING
                : isSaved
                ? SaveStatus.SAVED
                : !_.isEqual(iae, initialIae)
                ? SaveStatus.HAS_CHANGED
                : SaveStatus.NO_CHANGES

        return SaveStatus.NO_CHANGES
    }

    function onInputChange(
        e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>,
        language: LanguageType | null = null,
        dateType: AppEventDateEnum | null = null
    ) {
        const data = _.cloneDeep(iae)
        // handle languages
        if (language) {
            if (!data.localisations) data.localisations = []
            setAppEventLocalisation(
                data.localisations,
                language,
                e.target.name as AppEventLocalisationEnum,
                e.target.value
            )
            // remove all languages that have no translations
            data.localisations = data.localisations.filter(
                (l) =>
                    l.attributes?.name ||
                    l.attributes?.shortDescription ||
                    l.attributes?.longDescription
            )
        } else {
            if (dateType) {
                setAppEventDate(data.appEvent, dateType, e.target.value)
            } else {
                if (!data.appEvent.attributes) data.appEvent.attributes = {}
                // Fixme This is very tricky as not all attributes are strings
                // I would suggest to have a union type for possible e.target.name
                data.appEvent.attributes[
                    e.target.name as keyof AppEventAttributes
                ] = e.target.value as unknown as undefined
            }
        }
        setIae(data)
    }

    enum AppEventChecksEnum {
        IN_APP_PURCHASE,
        HIGH_PRIO,
    }

    function toggleCheckbox(checkType: AppEventChecksEnum, checked: boolean) {
        const data = _.cloneDeep(iae)
        if (!data.appEvent.attributes) data.appEvent.attributes = {}
        if (checkType === AppEventChecksEnum.IN_APP_PURCHASE)
            data.appEvent.attributes.purchaseRequirement = checked
                ? AppEventAttributesPurchaseRequirement.IN_APP_PURCHASE
                : AppEventAttributesPurchaseRequirement.NO_COST_ASSOCIATED
        if (checkType === AppEventChecksEnum.HIGH_PRIO)
            data.appEvent.attributes.priority = checked
                ? AppEventAttributesPriority.HIGH
                : AppEventAttributesPriority.NORMAL
        setIae(data)
    }

    function loadGoogleSheetData() {
        setIsGoogleSheetLoading(true)
        setGoogleSheetStatus(
            '🕑 Loading translations from Google Sheet. Please wait...'
        )
        // TODO: api call to load google sheet translations
        console.log('GET /gsheetdata')
        setGoogleSheetStatus('Translations were applied ✅')
        setIsGoogleSheetLoading(false)
    }

    async function saveIae() {
        setIsSaving(true)
        try {
            const { data: newIae } = isNew
                ? await axios.post<InAppEventType>('/api/v2/iae', iae)
                : await axios.patch<InAppEventType>(`/api/v2/iae/${id}`, iae)
            if (isNew) navigate(RouteTo.InAppEventsEdit(newIae.appEvent.id))
            setIae(newIae)
            setInitialIae(newIae)
            setIsSaved(true)
        } catch (e) {
            handleError('Error saving in-app event', e)
            setIsSaved(false)
        }
        setIsSaving(false)
    }

    async function deleteIae() {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete In-App Event',
            text: 'Really delete this In-App Event?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        try {
            await axios.delete(`/api/v2/iae/${iae.appEvent.id}`)
            navigate(RouteTo.InAppEvents())
        } catch (e) {
            handleError('Error deleting in-app event', e)
        }
    }

    if (isLoading) return <Loading />

    const { appEvent, localisations, screenshots } = iae

    return (
        <>
            <Section
                id="iaeOverview"
                title={`${isNew ? 'New' : 'Edit'} In-App Event: ${
                    appEvent.attributes?.referenceName
                } ${eventState ? `(${eventState})` : ''}`}
            >
                {/* https://developer.apple.com/help/app-store-connect/reference/in-app-event-badges */}
                <div className="part" style={{ marginBottom: 50 }}>
                    <h2>What kind of in-app event is this?</h2>
                    <label
                        data-tooltip-content="Activities that occur in real-time that all users can experience simultaneously — for example, a sports match or live-streamed concert. These events should provide users with new content, features, or goods."
                        data-tooltip-id="liveEvent"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.LIVE_EVENT}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.LIVE_EVENT
                            }
                            onChange={onInputChange}
                        />
                        Live Event
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="liveEvent" />

                    <label
                        data-tooltip-content="First-time availability of content or media — for example, newly released movies or audio."
                        data-tooltip-id="premiere"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.PREMIERE}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.PREMIERE
                            }
                            onChange={onInputChange}
                        />
                        Premiere
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="premiere" />

                    <label
                        data-tooltip-content="Activities encouraging the user to achieve a goal before the event ends — for example, a fitness challenge in a workout app or beating a certain number of levels in a game."
                        data-tooltip-id="challenge"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.CHALLENGE}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.CHALLENGE
                            }
                            onChange={onInputChange}
                        />
                        Challenge
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="challenge" />

                    <label
                        data-tooltip-content="Activities in which users compete against one another for the highest ranking or to win rewards — for example, a tournament where players battle to win the most matches."
                        data-tooltip-id="competition"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.COMPETITION}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.COMPETITION
                            }
                            onChange={onInputChange}
                        />
                        Competition
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="competition" />

                    <label
                        data-tooltip-content="Introducing new content, storylines, or media libraries — for example, a new season of a television show or a new battle pass in a game."
                        data-tooltip-id="newSeason"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.NEW_SEASON}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.NEW_SEASON
                            }
                            onChange={onInputChange}
                        />
                        New Season
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="newSeason" />

                    <label
                        data-tooltip-content="Introducing significant new features, content, or experiences – for example, the launch of new game modes or levels. These events go beyond minor enhancements like UI adjustments or bug fixes."
                        data-tooltip-id="majorUpdate"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.MAJOR_UPDATE}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.MAJOR_UPDATE
                            }
                            onChange={onInputChange}
                        />
                        Major Update
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="majorUpdate" />

                    <label
                        data-tooltip-content="Limited-time events that aren't captured by another event badge, possibly spanning multiple activities or experiences — for example, an event featuring a collaboration. These events should provide users with new content, features, or goods."
                        data-tooltip-id="specialEvent"
                    >
                        <input
                            disabled={!isEditable}
                            type="radio"
                            value={AppEventAttributesBadge.SPECIAL_EVENT}
                            name="badge"
                            checked={
                                appEvent.attributes?.badge ===
                                AppEventAttributesBadge.SPECIAL_EVENT
                            }
                            onChange={onInputChange}
                        />
                        Special Event
                    </label>
                    <Tooltip delayShow={500} place="bottom" id="specialEvent" />
                </div>

                <div>
                    <LabelInput
                        disabled={!isEditable}
                        object={appEvent.attributes}
                        size="wide"
                        name="referenceName"
                        tooltip="The reference name will be used to import the localized text from Google Sheet, and on App Store Connect for Sales and Trends reports. It won't be displayed on the App Store."
                        label="reference name"
                        onChange={(e) => onInputChange(e)}
                    />
                    <NewRow />
                    <LabelInput
                        disabled={!isEditable}
                        object={appEvent}
                        size="normal"
                        type="datetime-local"
                        name={AppEventDateEnum.PUBLISH_START}
                        value={getAppEventDate(
                            appEvent,
                            AppEventDateEnum.PUBLISH_START
                        )}
                        label="publish date"
                        onChange={(e) =>
                            onInputChange(
                                e,
                                null,
                                AppEventDateEnum.PUBLISH_START
                            )
                        }
                    />
                    <LabelInput
                        disabled={!isEditable}
                        object={appEvent}
                        size="normal"
                        type="datetime-local"
                        name={AppEventDateEnum.EVENT_START}
                        value={getAppEventDate(
                            appEvent,
                            AppEventDateEnum.EVENT_START
                        )}
                        label="start date"
                        onChange={(e) =>
                            onInputChange(e, null, AppEventDateEnum.EVENT_START)
                        }
                    />
                    <LabelInput
                        disabled={!isEditable}
                        object={appEvent}
                        size="normal"
                        type="datetime-local"
                        name={AppEventDateEnum.EVENT_END}
                        value={getAppEventDate(
                            appEvent,
                            AppEventDateEnum.EVENT_END
                        )}
                        label="end date"
                        onChange={(e) =>
                            onInputChange(e, null, AppEventDateEnum.EVENT_END)
                        }
                    />
                    <NewRow />
                    <LabelInput
                        disabled={!isEditable}
                        object={appEvent.attributes}
                        size="wide"
                        name="deepLink"
                        tooltip="A universal link or custom URL that directs users to your event’s content within the app."
                        label="deep link"
                        onChange={(e) => onInputChange(e)}
                    />
                    <LabelInput
                        disabled={true}
                        size="wide"
                        value={getAppEventUrl(iae)}
                        name="eventUrl"
                        tooltip="Auto-generated link that takes users directly to your active event’s details page. If the event is not active on the App Store, the link will take users to your app’s product page instead."
                        label="event url"
                        onChange={_.noop}
                    />
                    <NewRow />
                    <LabelInput
                        disabled
                        object={appEvent.attributes}
                        name="eventState"
                        size="semiwide"
                        tooltip={`The state of the event in the App Store. Events are editable only if their state is one of the followings: ${editableEventStates.join(
                            ', '
                        )}`}
                        label="event state"
                        onChange={_.noop}
                    />
                    <NewRow />
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            disabled={!isEditable}
                            label="Does this event access require In-App Purchase?"
                            checked={isInAppPurchaseRequired(appEvent)}
                            onToggle={() =>
                                toggleCheckbox(
                                    AppEventChecksEnum.IN_APP_PURCHASE,
                                    !isInAppPurchaseRequired(appEvent)
                                )
                            }
                        />
                        <Checkbox
                            disabled={!isEditable}
                            label="Is this a High Priority Event?"
                            checked={isHighPriority(appEvent)}
                            onToggle={() =>
                                toggleCheckbox(
                                    AppEventChecksEnum.HIGH_PRIO,
                                    !isHighPriority(appEvent)
                                )
                            }
                        />
                    </div>
                </div>
            </Section>
            <Section id="iaeDescription" title="Display name and description">
                <LabelInput
                    disabled={!isEditable}
                    object={appEvent}
                    size="wide"
                    name={AppEventLocalisationEnum.NAME}
                    value={getAppEventLocalisation(
                        localisations,
                        language,
                        AppEventLocalisationEnum.NAME
                    )}
                    tooltip={
                        'Localized name to appear on the App Store. Length should be max 30 characters.'
                    }
                    label={'display name in '}
                    labelHighlight={languages[language]}
                    onChange={(e) => onInputChange(e, language)}
                />
                <LabelInput
                    disabled={!isEditable}
                    object={appEvent}
                    size="wide"
                    name={AppEventLocalisationEnum.SHORT_DESCRIPTION}
                    value={getAppEventLocalisation(
                        localisations,
                        language,
                        AppEventLocalisationEnum.SHORT_DESCRIPTION
                    )}
                    tooltip={
                        'Localized short description which appears on your event card in places like the Today tab and Search on the App Store. Length should be max 50 characters.'
                    }
                    label={'short description in '}
                    labelHighlight={languages[language]}
                    onChange={(e) => onInputChange(e, language)}
                />
                <NewRow />
                <LabelInput
                    disabled={!isEditable}
                    object={appEvent}
                    size="doublewide"
                    name={AppEventLocalisationEnum.LONG_DESCRIPTION}
                    value={getAppEventLocalisation(
                        localisations,
                        language,
                        AppEventLocalisationEnum.LONG_DESCRIPTION
                    )}
                    tooltip={
                        'Localized long description that will appear on your event details page when users tap your event card. Max length is 120 characters.'
                    }
                    label={'long description in '}
                    labelHighlight={languages[language]}
                    onChange={(e) => onInputChange(e, language)}
                />
                <NewRow />

                {isGoogleSheetLoading ? (
                    <SpeechBubble
                        style={{
                            justifyContent: 'left',
                            marginTop: 20,
                            marginBottom: 10,
                        }}
                    >
                        {googleSheetStatus}
                    </SpeechBubble>
                ) : (
                    <button
                        disabled={!isEditable}
                        style={{ marginTop: 20 }}
                        onClick={loadGoogleSheetData}
                    >
                        Load from Google Sheet
                    </button>
                )}
            </Section>
            <Section id="iaeImages" style={{ marginBottom: 50 }} title="Images">
                <h3>
                    In-App Event Images (16:9 or 9:16 ⸺ 1920 x 1080 up to 3840 x
                    2160)
                </h3>
                {_.isEmpty(screenshots) && (
                    <div className="noMediaWrapper">
                        <label className="placeholder">
                            No images, just yet. Click 'Add new' to upload
                        </label>
                    </div>
                )}
                {screenshots?.map((screenshot) => (
                    <MediaImage
                        key={screenshot.id}
                        media={mapAppEventScreenshotToMedia(
                            screenshot,
                            640,
                            360
                        )}
                        {...(isEditable && {
                            onClick: _.noop, // TODO pass the function that will delete image
                            buttonType: ImageIconType.DELETE,
                        })}
                    />
                ))}
                <div style={{ marginTop: 20 }}>
                    <input
                        disabled={!isEditable}
                        type="file"
                        // TODO Check if we will allow to upload multiple files at the same time
                        multiple={true}
                        name="iaeMediaUpload"
                        id="iaeMediaUpload"
                        // TODO Check what file types are acceptable
                        accept="Image/png"
                        // TODO add uploading media handler
                        onChange={_.noop}
                    />
                    <label
                        className={classNames(
                            'upload',
                            !isEditable && 'disabled'
                        )}
                        htmlFor="iaeMediaUpload"
                    >
                        Add new
                    </label>
                </div>
            </Section>
            <SavePanel
                title={`Working with: ${appEvent.attributes?.referenceName}`}
                validationResults={validationResults}
                item={iae}
                isDisabled={!isEditable}
                save={saveIae}
                showDelete={!isNew}
                saveStatus={getSaveStatus(isSaved, isSaving)}
                onDelete={deleteIae}
            />
        </>
    )
}

export default InAppEvent
