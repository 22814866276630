import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropResult } from '@hello-pangea/dnd'

import CarouselsTable from './CarouselsTable'
import { Loading, Modal, NewRow, Section } from '../shared'

import {
    useCarousels,
    useCopyCarousel,
    useDeleteCarousel,
    useToggleActiveCarousel,
    useUpdateCarouselOrder,
} from '../hooks/carousels'

import { arrayMove, handleError } from '../utils/utils'

import { RouteTo } from '../routes'

import { Carousel } from '../types/carouselTypes'

const Carousels = () => {
    const navigate = useNavigate()
    const {
        carousels,
        isPending: isCarouselsPending,
        error: carouselsError,
    } = useCarousels()
    const [carouselsDraft, setCarouselsDraft] = useState<Carousel[]>([])
    const { updateCarouselOrder, error: updateCarouselOrderError } =
        useUpdateCarouselOrder()
    const { toggleActiveCarousel, error: toggleActiveCarouselError } =
        useToggleActiveCarousel()
    const { copyCarousel, loadingMessage: copyLoadingMessage } =
        useCopyCarousel()
    const { deleteCarousel, error: deleteCarouselError } = useDeleteCarousel()

    useEffect(() => {
        if (carousels) {
            setCarouselsDraft(carousels)
        }
    }, [carousels])

    useEffect(() => {
        if (updateCarouselOrderError) {
            handleError(
                'Failed to update carousel order',
                updateCarouselOrderError
            )
            if (carousels) {
                setCarouselsDraft(carousels)
            }
        }
    }, [updateCarouselOrderError])

    useEffect(() => {
        if (carouselsError) {
            handleError('Failed to get carousels', carouselsError)
        }
    }, [carouselsError])

    useEffect(() => {
        if (toggleActiveCarouselError) {
            handleError(
                'Failed to toggle active status',
                toggleActiveCarouselError
            )
        }
    }, [toggleActiveCarouselError])

    useEffect(() => {
        if (deleteCarouselError) {
            handleError('Failed to delete carousel', deleteCarouselError)
        }
    }, [deleteCarouselError])

    const onClickDelete = async (i: number) => {
        const isConfirmed = await Modal.confirm({
            heading: 'Delete carousel',
            text: 'Really delete this carousel?',
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        deleteCarousel(carouselsDraft[i].carouselId)
    }

    const onCarouselDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        const reorderedCarousels = arrayMove(
            carouselsDraft.slice(),
            result.source.index,
            result.destination.index
        )
        const reorderedIds = reorderedCarousels.map((c) => c.carouselId)

        setCarouselsDraft(reorderedCarousels)
        updateCarouselOrder(reorderedIds)
    }

    const onActivateCarouselClick = async (carousel: Carousel) => {
        const { carouselId, carouselName } = carousel
        const isSetActive = !carousel.active

        const isConfirmed = await Modal.confirm({
            heading: `${isSetActive ? 'Activate' : 'Deactivate'} carousel`,
            text: `${
                isSetActive ? 'Activate' : 'Deactivate'
            } carousel with name "${carouselName}"?`,
            okLabel: 'Yes do it!',
        })
        if (!isConfirmed) return

        toggleActiveCarousel({ carouselId, isSetActive })
    }

    if (isCarouselsPending) return <Loading />
    if (copyLoadingMessage) return <Loading text={copyLoadingMessage} />

    return (
        <Section>
            <div style={{ display: 'inline-block' }}>
                <h1>Carousels</h1>
                <button
                    className="new withHeading"
                    onClick={() => navigate(RouteTo.CarouselNew())}
                >
                    Add new
                </button>
            </div>
            <NewRow />
            <CarouselsTable
                carousels={carouselsDraft}
                onCarouselDeleteClick={onClickDelete}
                onCopyCarouselClick={copyCarousel}
                onCarouselDragEnd={onCarouselDragEnd}
                onActivateCarouselClick={onActivateCarouselClick}
            />
        </Section>
    )
}

export default Carousels
