import { useContext } from 'react'

import { CurrentEnvironmentContext, OpenSectionsContext } from '../app/Contexts'

export const useOpenSections = () => {
    const context = useContext(OpenSectionsContext)

    if (!context) {
        throw new Error(
            'useOpenSections must be used within an OpenSectionsProvider'
        )
    }

    return context
}

export const useCurrentEnvironment = () => {
    const context = useContext(CurrentEnvironmentContext)

    if (!context) {
        throw new Error(
            'useCurrentEnvironment must be used within a CurrentEnvironmentProvider'
        )
    }

    return context
}
