import _ from 'lodash'

import { SaveStatus } from '../shared/SavePanel'

import { LanguageType } from '../types/enums/languagetype.enum'

import {
    Carousel,
    CarouselItem,
    CreateCarousel,
    CreateCarouselItem,
} from '../types/carouselTypes'
import { Pack } from '../types/packTypes'

export function mapCarouselItemsToPacks(
    items: (CarouselItem | CreateCarouselItem)[],
    allPacks: Pack[]
) {
    return items.map((item) => {
        const pack = allPacks.find((pack) => pack.packId === item.packId)
        if (!pack) {
            throw new Error(
                'Carousel contains a reference to a pack that does not exist'
            )
        }
        return pack
    })
}

export function getTranslationLoadingText(
    isTranslationCategoryId: string | undefined,
    translations: Record<LanguageType, string> | undefined,
    isPending: boolean,
    error: Error | null
) {
    if (isTranslationCategoryId === undefined) {
        return ''
    }
    if (error) {
        return `That didn't work. Sorry! Server says: ${error.toString()}`
    }
    if (isPending) {
        return 'Loading from google...'
    }
    if (typeof translations === 'object' && _.isEmpty(translations)) {
        return 'There are no translations for this Category ID!'
    }
    if (translations) {
        return "I've updated the text for you!"
    }
    return ''
}

export function isCarouselsEqual(original: Carousel, draft: CreateCarousel) {
    const originalPackIds = original.items.map((item) => item.packId)
    const draftPackIds = draft.items.map((item) => item.packId)

    return (
        _.isEqual(_.omit(original, 'items'), _.omit(draft, 'items')) &&
        _.isEqual(originalPackIds, draftPackIds)
    )
}

type GetSavedStatusProps = {
    original?: Carousel
    draft: CreateCarousel
    isSaving: boolean
    isSaved: boolean
}

export function getSaveStatus(
    getSavedStatusProps: GetSavedStatusProps
): SaveStatus {
    const { original, draft, isSaving, isSaved } = getSavedStatusProps
    if (isSaving) {
        return SaveStatus.SAVING
    }
    if (!original || !isCarouselsEqual(original, draft)) {
        return SaveStatus.HAS_CHANGED
    }
    if (isSaved) {
        return SaveStatus.SAVED
    }
    return SaveStatus.NO_CHANGES
}
