import { useCurrentEnvironment } from '../../hooks/contexts.tsx'

import { getBackendBasePath } from '../../utils/getBackendBasePath.ts'
import { getRedirectPath } from '../../utils/redirectToLogin.ts'
import { getLogoByEnvName } from '../../utils/utils.ts'

import styles from './Login.module.scss'

function Login() {
    const { currentEnvName } = useCurrentEnvironment()
    const loginWithGoogle = () => {
        const redirectPath = getRedirectPath()
        let loginUrl = `${getBackendBasePath()}/auth/google`

        if (redirectPath) {
            loginUrl += `?redirectTo=${redirectPath}`
        }

        window.location.assign(loginUrl)
    }

    return (
        <div tabIndex={0} className={styles.loginWrapper}>
            <div className={styles.centeredBlock}>
                <img
                    alt="tocaboca_logo"
                    className={styles.logoImg}
                    src={getLogoByEnvName(currentEnvName)}
                />

                <div className={styles.title}>Shop CMS</div>
                <div className={styles.buttonBlock}>
                    <button onClick={loginWithGoogle}>Login with Google</button>
                </div>
            </div>
        </div>
    )
}

export default Login
