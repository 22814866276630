/**
 * The ORDER ACTUALLY MATTERS!
 */
export enum UpdateStatus {
    Error,
    NotStarted,
    Scheduled,
    Updating,
    Updated,
    Invalid,
}
